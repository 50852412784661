import React, { useState, useEffect } from "react";
import { CountrySelect } from "..";
import { doGET } from "../../util/HttpUtil";

export default function PhoneNumWithValidation({
  className,
  label = "Mobile Phone",
  value: initialValue,
  onChange,
  isDisabled,
  notifyParent,
  showErr,
  inline,
  required,
  borderNone,
  onClickRight,
  outline,
  onblur,
  inputStyle,
  group = "Individuals_Lite",
  countrySelectStyle,
  RightComponent,
  err,
  form_id,
  rightComponentStyle = {},
}) {
  const [value, setValue] = useState(initialValue || { countryCode: '', phone: '' });
  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setError(err);
    setHasError(showErr);
  }, [err, showErr]);

  const handleChange = async (newData) => {
    if (newData?.countryCode) {
      await validatePhoneNumber({ newDataCountryCode: newData?.countryCode });
    }
    const updatedValue = { ...value, ...newData };
    setValue(updatedValue);
    onChange(updatedValue);
  };

  const validatePhoneNumber = async ({ newDataCountryCode }) => {
    if (!value.phone) return; // Skip validation if no phone number is entered

    setLoading(true);

    const response = await doGET(`/api/cyclos/verify/field?group=${group}&value=${value.phone}&field=mobilePhone&mobileCountryCode=${newDataCountryCode ?? value.countryCode}&form_id=${form_id}`);
    setLoading(false);

    if (response.status === 204) {
      notifyParent(true);
      setError(null);
      setHasError(false);
    } else if (response.status === 200 && response.data) {
      setError(response.data);
      setHasError(true);
      notifyParent(false);
    }
  };


  return (
    <div className={`${className} ${error ? 'error' : ''} mt-2`}>
      {outline ??
        <div style={{ minWidth: "120px ", fontSize: 16, margin: "0px 0px 5px 5px", fontWeight: "500", color: "#495057" }}>
          {label}{required && <font color="red">*</font>}
        </div>
      }
      <div
        style={{ margin: "0px 0px 5px 0px", height: "36px" }}
        className={" input-group flex-1 "}
      >
        <div className="input-group-prepend">
          <CountrySelect
            value={value.countryCode}
            onChange={(v) => handleChange({ countryCode: v.value })}
            disabled={isDisabled}
            innerContainerStyle={{ borderRadius: "15px 0px 0px 15px", }}
            outline
            style={{ borderRadius: "15px 0px 0px 15px", ...countrySelectStyle }}
            className={`m-0 `}
            noLabel
            inputGroup
            showErr={showErr}
            required={required}
            // error={error}
            inline={inline}
            borderNone={borderNone}
            isDisabled={isDisabled}
            isd={true}
          />
        </div>
        <input
          style={{
            height: "50px",
            borderRadius: "0px 15px 15px 0px",
            paddingLeft: "5px",
            borderTop: "none",
            borderBottom: "none",
            borderRight: "none",
            borderTop: "none",
            fontSize: "14px",
            outline: "none",
            backgroundColor: "#F1FAFC",
            ...inputStyle
          }}
          type="number"
          className="form-control"
          placeholder="Mobile Phone"
          value={value.phone}
          onChange={(e) => handleChange({ phone: e.target.value })}
          onBlur={validatePhoneNumber}
          disabled={isDisabled}
          required
        />

      </div>
      {RightComponent && (
        <div
          onClick={(e) => (onClickRight ? onClickRight(e) : null)}
          style={{ height: "32px", marginRight: '0px', ...rightComponentStyle }}
          className={onClickRight ? "component_pointer inputfield__rightComponent" : "inputfield__rightComponent "}
        >
          {RightComponent()}
        </div>
      )}
      {(hasError && !loading && error) && <div className="error-message mt-3"> <em className="selectfield__error">{error}</em></div>}
      {loading ? <div className="selectfield__info mt-3">Validating Mobile Phone...</div> : null}

    </div>
  );
}
