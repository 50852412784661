import React from 'react';
import { Row } from 'reactstrap';
import { CountrySelect, InputField, SelectField } from '../../../../../components';
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';
import { InputLabel } from './ProofOfDocument';
import "./style.css";
import PercentageInput from './PercentageInput';
import AntDateSelect from '../../../../../components/DateSelects/AntDateSelect';
import CheckBoxFieldMutiple from '../../../../../components/CheckBox/CheckBoxFieldMutiple';
const ACCESS_TYPES = [
    { id: 0, type: "Viewer", text: "View payments and create new beneficiaries." },
    { id: 1, type: "Basic User", text: "All access of Viewer and initiate new payments. Payments initiated by basic users will require authorisation from owner (or) approver (or) a power user before the payment is submitted for further processing. Basic user cannot authorise payments." },
    { id: 2, type: "Approver", text: "All access of Basic User and approve payments initiated by others. Approver’s own initiated transactions will require authorisation from another Approver, Power User or Owner." },
    { id: 3, type: "Power User", text: "Same access as Owner" },
]

const User = ({ asLabel, data, handleChange, hasError, index, handleValidation, validationkeyValue }) => {
    return (
        <div className='mt-2'>

            <QuestionRadioGroup
                field={`individuals[${index}].isShareHolder`}
                style={{ fontSize: 15 }}
                question="Is this person a shareholder of the entity?"
                data={data}
                handleChange={handleChange}
                error={handleValidation('isShareHolder', data?.individuals?.[index].isShareHolder)}
                hasError={hasError}
                // error={hasError}
                isDisabled={asLabel}
                required
            // defaultValue={index == 0 ? true : null}

            />

            <QuestionRadioGroup
                field={`individuals[${index}].isAuthorizedUser`}
                style={{ fontSize: 15 }}
                question="Is this person an authorized user of the entity?"
                data={data}
                handleChange={handleChange}
                error={handleValidation('isAuthorizedUser', data?.individuals?.[index].isAuthorizedUser)}
                hasError={hasError}
                // error={hasError}
                isDisabled={asLabel || index == 0}
                required
                defaultValue={index == 0 ? true : null}
            />





            <InputLabel required label={"Name"} labelStyle={{ fontSize: 15 }} />
            <Row>
                <InputField
                    className="col-sm-4"
                    placeholder="First"
                    value={data?.individuals?.[index]?.firstName}
                    onChange={(v) => handleChange(`individuals[${index}].firstName`, v)}
                    error={handleValidation("userfirstName", data?.individuals?.[index]?.firstName)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                />
                <InputField
                    className="col-sm-4"
                    placeholder="Middle"
                    value={data?.individuals?.[index]?.middleName}
                    onChange={(v) => handleChange(`individuals[${index}].middleName`, v)}
                    error={handleValidation("userMiddleName", data?.individuals?.[index]?.middleName)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                />
                <InputField
                    className="col-sm-4"
                    placeholder="Last"
                    value={data?.individuals?.[index]?.lastName}
                    onChange={(v) => handleChange(`individuals[${index}].lastName`, v)}
                    error={handleValidation(`userlastName`, data?.individuals?.[index]?.lastName)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                />
            </Row>

            <InputLabel required label={"Address"} labelStyle={{ fontSize: 15 }} />
            <Row>
                <InputField
                    placeholder="Building and Number"
                    value={data?.individuals?.[index]?.address?.buildingNumber}
                    onChange={(v) => handleChange(`individuals[${index}].address.buildingNumber`, v)}
                    error={handleValidation(`userBuildingNumber`, data?.individuals?.[index]?.address?.buildingNumber)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                />
            </Row>
            <Row>
                <InputField
                    placeholder="Street name"
                    value={data?.individuals?.[index]?.address?.street}
                    onChange={(v) => handleChange(`individuals[${index}].address.street`, v)}
                    error={handleValidation(`userStreet`, data?.individuals?.[index]?.address?.street)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                />
            </Row>
            <Row>
                <InputField
                    className="col-sm-6 col-md-3"
                    placeholder="City"
                    value={data?.individuals?.[index]?.address?.city}
                    onChange={(v) => handleChange(`individuals[${index}].address.city`, v)}
                    error={handleValidation(`userCity`, data?.individuals?.[index]?.address?.city)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                />
                <InputField
                    className="col-sm-6 col-md-3"
                    placeholder="State / Province / Region"
                    value={data?.individuals?.[index]?.address?.state}
                    onChange={(v) => handleChange(`individuals[${index}].address.state`, v)}
                    error={handleValidation(`userState`, data?.individuals?.[index]?.address?.state)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                />
                <InputField
                    className="col-sm-6 col-md-3"
                    placeholder="Postal / Zip Code"
                    type="text"
                    value={data?.individuals?.[index]?.address?.pinCode}
                    onChange={(v) => handleChange(`individuals[${index}].address.pinCode`, v)}
                    error={handleValidation(`userPinCode`, data?.individuals?.[index]?.address?.pinCode)}
                    showErr={hasError}
                    isDisabled={asLabel}
                />
                <CountrySelect
                    style={{ marginTop: '20px !important' }}
                    value={data?.individuals?.[index]?.address?.country}
                    onChange={(v) => handleChange(`individuals[${index}].address.country`, v?.value)}
                    error={handleValidation('userCountry', data?.individuals?.[index]?.address?.country)}
                    showErr={hasError}
                    noLabel
                    isoCode
                    placeholder={"Select Country"}
                    className="col-sm-6 col-md-3 with-margin-top"
                    isDisabled={asLabel}
                />
            </Row>

            <Row>
                <InputField
                    className="col-sm-6"
                    label={"Phone"}
                    type="number"
                    value={data?.individuals?.[index]?.phone}
                    onChange={(v) => handleChange(`individuals[${index}].phone`, v)}
                    error={handleValidation("userphone", data?.individuals?.[index]?.phone)}
                    showErr={hasError}
                    isDisabled={asLabel}
                    required
                />
                <AntDateSelect
                    placeholder={"dd/mm/yyyy"}
                    className="col-sm-6"
                    format="dd/mm/yyyy"
                    label="Date of Birth"
                    value={data?.individuals?.[index]?.dob}
                    onChange={(v) => handleChange(`individuals[${index}].dob`, v)}
                    error={handleValidation("userDOB", data?.individuals?.[index]?.dob)}
                    showErr={hasError}
                    isDisabled={asLabel}
                    required = {true}
                />
                <InputField
                    className="col-sm-6"
                    label={"Email"}
                    type="email"
                    value={data?.individuals?.[index]?.email}
                    onChange={(v) => handleChange(`individuals[${index}].email`, v)}
                    error={handleValidation(`useremail`, data?.individuals?.[index]?.email)}
                    showErr={hasError}
                    isDisabled={asLabel}
                    required
                />
                <InputField
                    className="col-sm-6"
                    label={"Mobile Phone"}
                    type="number"
                    value={data?.individuals?.[index]?.mobilePhone}
                    onChange={(v) => handleChange(`individuals[${index}].mobilePhone`, v)}
                    error={handleValidation(`usermobilePhone`, data?.individuals?.[index]?.mobilePhone)}
                    showErr={hasError}
                    required
                    isDisabled={asLabel}
                />
                {data?.individuals?.[index]?.isAuthorizedUser ? <SelectField
                    labelStyle={{ fontSize: 15 }}
                    className="col-sm-6"
                    label="Role"
                    data={validationkeyValue["role"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                    isDisabled={asLabel}
                    value={data?.individuals?.[index]?.role}
                    onChange={(v) => handleChange(`individuals[${index}].role`, v?.value)}
                    error={handleValidation(`role`, data?.individuals?.[index]?.role)}
                    showErr={hasError}
                    placeholder="Select"
                    required
                /> : null}

                {data?.individuals?.[index]?.isShareHolder ?
                    <InputField
                        className="col-sm-6"
                        label={"TAX ID or Passport Number"}
                        placeholder={"TAX ID or Passport Number"}
                        value={data?.individuals?.[index]?.taxId}
                        onChange={(v) => handleChange(`individuals[${index}].taxId`, v)}
                        error={handleValidation(`taxId`, data?.individuals?.[index]?.taxId)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                        required
                    /> : null}
                {data?.individuals?.[index]?.isShareHolder ? <div className="col-12 col-sm-6">
                    <InputLabel label={"Nationality"} labelStyle={{ fontSize: 15 }} required />
                    <CountrySelect
                        placeholder={"Select Nationality"}
                        isDisabled={asLabel}
                        noLabel
                        isoCode
                        value={data?.individuals?.[index]?.nationality}
                        onChange={(v) => handleChange(`individuals[${index}].nationality`, v?.value)}
                        error={handleValidation('shareholderCountry', data?.individuals?.[index]?.nationality)}
                        showErr={hasError}
                    />
                </div> : null}

                {data?.individuals?.[index]?.isAuthorizedUser ? <SelectField
                    labelStyle={{ fontSize: 15 }}
                    className="col-12 col-sm-6"
                    label="Account Access Type"
                    data={validationkeyValue["accessType"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                    isDisabled={asLabel}
                    placeholder="Select Account Accesss Type"
                    value={data?.individuals?.[index]?.accessType}
                    onChange={(v) => handleChange(`individuals[${index}].accessType`, v?.value)}
                    error={handleValidation(`accessType`, data?.individuals?.[index]?.accessType)}
                    showErr={hasError}
                    required
                /> : null}

                {data?.individuals?.[index]?.isShareHolder ? <PercentageInput
                    className="col-sm-6"
                    label={"Percentage of Ownership"}
                    value={data?.individuals?.[index]?.percentOwner ? data?.individuals?.[index]?.percentOwner : ""}
                    onChange={(e) => {
                        handleChange(`individuals[${index}].percentOwner`, e);
                    }}
                    isDisabled={asLabel}
                    error={handleValidation(`percentOwner`, data?.individuals?.[index]?.percentOwner)}
                    showErr={hasError}
                    required
                /> : null}


            </Row>

            {data?.individuals?.[index]?.isAuthorizedUser ? <div>
                {
                    ACCESS_TYPES.map(e => (<div className='my-2 ps-2 fs-6' key={e.id}><span className="bold-text">{e.type}: </span>{e.text}</div>))
                }
                <QuestionRadioGroup
                    field={`individuals[${index}].haveControlOverEntity`}
                    style={{ fontSize: 15 }}
                    question="Is this person an Officer or a person with control over the entity?"
                    data={data}
                    handleChange={handleChange}
                    isDisabled={asLabel}
                    //defaultValue={false}
                    error={handleValidation(`haveControlOverEntity`, data?.individuals?.[index]?.haveControlOverEntity)}
                    hasError={hasError}
                    required

                />

                <QuestionRadioGroup
                    field={`individuals[${index}].isDirector`}
                    style={{ fontSize: 15 }}
                    question="Is this person a Director of the entity?"
                    data={data}
                    handleChange={handleChange}
                    error={handleValidation('isDirector', data?.individuals?.[index].isDirector)}
                    hasError={hasError}
                    isDisabled={asLabel}
                    //defaultValue={false}
                    required

                />

                <QuestionRadioGroup
                    field={`individuals[${index}].isAuthorizedContact`}
                    style={{ fontSize: 15 }}
                    question="Is this person an authorized contact on the account?"
                    data={data}
                    handleChange={handleChange}
                    error={handleValidation('isAuthorizedContact', data?.individuals?.[index].isAuthorizedContact)}
                    hasError={hasError}
                    isDisabled={asLabel}
                    required
                //defaultValue={false}
                />

                <QuestionRadioGroup
                    field={`individuals[${index}].isAgentWithAttorneyPower`}
                    style={{ fontSize: 15 }}
                    question="Is this person an agent of the company operating under a power of attorney?"
                    data={data}
                    handleChange={handleChange}
                    error={handleValidation('isAgentWithAttorneyPower', data?.individuals?.[index].isAgentWithAttorneyPower)}
                    isDisabled={asLabel}
                    hasError={hasError}
                    required
                />
            </div> : null}




            {data?.individuals?.[index]?.isShareHolder ? <Row>
                <CheckBoxFieldMutiple
                    labelStyle={{ fontSize: 15 }}
                    label="Source(s) of Wealth"
                    data={validationkeyValue["sourceOfWealth"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                    placeholder="Select"
                    value={data?.individuals?.[index]?.sourceOfWealthList ? data?.individuals?.[index]?.sourceOfWealthList : []}
                    onChange={(v) => {
                        // console.log(v);
                        handleChange(`individuals[${index}].sourceOfWealthList`, v)
                    }}
                    error={handleValidation('sourceOfWealth', data?.individuals?.[index]?.sourceOfWealthList)}
                    showErr={hasError}
                    isDisabled={asLabel}
                    required
                />

            </Row> : null}

        </div>
    )
}

export default User;