import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { InputField, SelectField } from '../../../../../components';
import GoBackButton from '../../../../../components/Buttons/GoBackButton';
import NextButton from '../../../../../components/Buttons/NextButton';
import CheckBoxFieldMutiple from "../../../../../components/CheckBox/CheckBoxFieldMutiple";
import CurrencyField from '../../../components/CurrencyField/CurrencyField';
import { DialogContext } from '../../../../../store/context/DialogContext';

const errorMessages = {
    hasBankAccount: "Please answer whether you have a bank account at another financial institution.",
    hasCriminalRecord: "Please answer whether you have been charged or convicted of a crime.",
    isPoliticallyExposed: "Please answer whether you are considered a politically exposed person.",
    employmentStatus: "Please select your Employment Status.",
    annualSalary: "Please enter your Annual Salary or Income.",
    depositLocations: "Please select the locations you intend on receiving deposits from.",
    paymentLocations: "Please select the locations you intend on making payments to.",
    willOrderDebitCard: "Please answer whether you would like to order a debit card.",
    interestedInCrypto: "Please answer whether you plan on buying, holding, or selling cryptocurrency.",
};

export function validateRequired(fields, data) {
    let errors = [];
    fields.forEach(({ field, fieldType }) => {
        const fieldValue = _.get(data, field);
        if (
            fieldValue === undefined ||
            fieldValue === null ||
            !fieldValue ||
            (Array.isArray(fieldValue) && fieldValue.length === 0)
        ) {
            if (fieldType === 'checkbox' && ![false, true]?.includes(fieldValue)) {
                errors.push(errorMessages[field] || "Please answer this question.");
            } else if (fieldType !== 'checkbox') {
                errors.push(errorMessages[field] || `Please provide a valid ${field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}.`);
            }
        }
    });
    return errors;
}

const DeclarationsForm = ({
    hideButtons,
    asLabel,
    data,
    handleChange,
    handleUpdate,
    hasError,
    setHasError,
    setErrorMessage,
    loading,
    setStep,
    cyclosFields
}) => {

    const { showError } = useContext(DialogContext);

    const questions1 = [
        { field: 'hasBankAccount', question: 'Do you have a bank account at another financial institution?' },
        { field: 'hasCriminalRecord', question: 'Have you been charged/convicted of a crime by any law enforcement or government in the past 5 years?' },
        { field: 'isPoliticallyExposed', question: 'Are you considered a politically exposed person?' },
    ];

    const questions2 = [
        { field: 'willOrderDebitCard', question: 'Would you like to order a debit card with your account?' },
        { field: 'interestedInCrypto', question: 'Do you plan on buying, holding, or selling cryptocurrency in your account?' }
    ];

    const handleSubmit = () => {
        if (data?.annualSalary > 2147483647) return showError("Currency should be less than 2147483647");

        const requiredFields = [
            { field: 'hasBankAccount', fieldType: 'checkbox' },
            { field: 'hasCriminalRecord', fieldType: 'checkbox' },
            { field: 'isPoliticallyExposed', fieldType: 'checkbox' },
            { field: 'employmentStatus', fieldType: null },
            { field: 'annualSalary', fieldType: null },
            { field: 'depositLocations', fieldType: null },
            { field: 'paymentLocations', fieldType: null },
            { field: 'willOrderDebitCard', fieldType: 'checkbox' },
            { field: 'interestedInCrypto', fieldType: 'checkbox' },
        ];

        const errors = validateRequired(requiredFields, data);

        if (errors.length > 0) {
            setHasError(true);
            setErrorMessage(errors.join(' '));
            showError(errors[0]);
            return;
        }

        setHasError(false);
        handleUpdate({ nextStep: 3, payload: data });
    };

    return (
        <div className='row pt-3'>
            {questions1.map(({ field, question }) => (
                <QuestionRadioGroup
                    key={field}
                    field={field}
                    question={question}
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    isDisabled={asLabel}
                    required
                    error={validateRequired([{ field, fieldType: 'checkbox' }], data)[0]}
                />
            ))}

            <SelectField
                label="Employment Status"
                required
                className="mt-2"
                defaultValue={"Employed"}
                value={data?.employmentStatus}
                onChange={(v) => handleChange('employmentStatus', v?.value)}
                data={cyclosFields?.employmentStatus?.options}
                placeholder="Select Employment Status"
                error={validateRequired([{ field: 'employmentStatus', fieldType: null }], data)[0]}
                showErr={hasError}
                isDisabled={asLabel}
            />

            <CurrencyField
                label="Annual Salary or Income (USD)"
                className="col-12"
                value={data?.annualSalary}
                onChange={(v) => handleChange('annualSalary', v)}
                isDisabled={asLabel}
                showErr={hasError}
                err={validateRequired([{ field: 'annualSalary', fieldType: null }], data)[0]}
                required
                notifyParent={(isValid) => {
                }}
            />

            <CheckBoxFieldMutiple
                label="Locations you intend on receiving deposits from."
                data={cyclosFields?.depositLocations?.options}
                placeholder="Select"
                value={data.depositLocations || []}
                onChange={(v) => handleChange('depositLocations', v)}
                labelStyle={{ fontSize: 16 }}
                error={validateRequired([{ field: 'depositLocations', fieldType: null }], data)[0]}
                isDisabled={asLabel}
                showErr={hasError}
                required
            />

            <CheckBoxFieldMutiple
                label="Locations you intend on making payments to."
                data={cyclosFields?.paymentLocations?.options}
                placeholder="Select Locations"
                labelStyle={{ fontSize: 16 }}
                value={data.paymentLocations || []}
                onChange={(v) => handleChange('paymentLocations', v)}
                error={validateRequired([{ field: 'paymentLocations', fieldType: null }], data)[0]}
                isDisabled={asLabel}
                showErr={hasError}
                required
            />

            {questions2.map(({ field, question }) => (
                <QuestionRadioGroup
                    key={field}
                    required
                    field={field}
                    question={question}
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    isDisabled={asLabel}
                    error={validateRequired([{ field, fieldType: 'checkbox' }], data)[0]}
                />
            ))}

            {!hideButtons && (
                <div className='d-flex w-100 justify-content-between mt-3'>
                    <GoBackButton loading={loading} onClick={() => setStep(1)} />
                    <NextButton loading={loading} onClick={handleSubmit} />
                </div>
            )}
        </div>
    );
};

export const QuestionRadioGroup = ({ isDisabled, field, question, data, handleChange, hasError, style, defaultValue, required, error }) => {
    useEffect(() => {
        if (_.get(data, field) === undefined && defaultValue !== undefined) {
            handleChange(field, defaultValue);
        }
    }, [data, field, handleChange, defaultValue]);

    return (
        <div className='ps-2 mb-3'>
            <FormGroup >
                <Form.Label className='' style={{ minWidth: "120px", padding: "0px 0px 0px 0px", fontSize: 16, fontWeight: "500", ...style }}>
                    {question}
                    {required && <font color="red">*</font>}
                </Form.Label>
                <div className='d-flex ps-0'>
                    <Form.Check
                        disabled={isDisabled}
                        className='me-4'
                        type="radio"
                        label="Yes"
                        name={field}
                        value="true"
                        checked={_.get(data, field) === true}
                        onChange={() => handleChange(field, true)}
                    />
                    <Form.Check
                        disabled={isDisabled}
                        type="radio"
                        label="No"
                        name={field}
                        value="false"
                        checked={_.get(data, field) === false}
                        onChange={() => handleChange(field, false)}
                    />
                </div>
                {/* {console.log(error, hasError)} */}
                {error && hasError && <div className="ps-2 mt-1 ms-0 w-100 error__message  left-0">{error || ""}</div>}
            </FormGroup>
        </div>
    );
};

export default DeclarationsForm;
